.parent >div{
    width: 20%;
}
.parent >div>ul{
    width: 80%;
    position: absolute;
}
.parent{
    text-align: center;
}

.headerimg{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(rgba(15, 23, 43, .9), rgba(15, 23, 43, .9)), url(./assets/img/bg-header.jpg);
}
.crd:hover{
    color: white;
}
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.hd{
    animation: transitionIn 1s ;
}
.activeClass{
    color: #fd7e14 !important;
}
.active1{
    background-color: #fd7e14;
}
.active{
    color: #fd7e14;
}
.orange{
    color: #fd7e14;
}
.white{
    color: white !important;
}
@keyframes transitionIn {
    from{
        opacity: 0;
        transform: translateX(-100px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}
.nv{
    animation: navTransition 1s ;
}
@keyframes navTransition {
    from{
        opacity: 0;
        transform: translateY(-20px);
    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
}

.sr{
    animation: serTransiton 1s;
    animation-fill-mode: both;
}
@keyframes serTransiton {
    from{
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to{
        opacity: 1;
        transform: none;

    }
}
.scrl{
    animation: scrollTop 0.5s ;
}
@keyframes scrollTop {
    from{
        transform: translateY(32px);
    }
    to{
        transform: translateY(-1px);
    }
}
.addtocrt{
    animation: addtocrt 0.5s ;
}
@keyframes addtocrt {
    from{
        transform: translateX(62px);
    }
    to{
        transform: translateX(-1px);

    }
}
.adt{
    color: red;
    font-size: 4rem;
    animation: adtoctd 1s  3 ease-in forwards;
}
@media screen and (min-width: 360px){
    .adt{
        color: red;
        font-size: 2.5rem;
        animation: adtoctd 1s  3 ease-in forwards;
    }
}
@media screen and (min-width: 768px){
    .adt{
        color: red;
        font-size: 4rem;
        animation: adtoctd 1s  3 ease-in forwards;
    }
}
@keyframes adtoctd {
    from{
        color: red;
    }
    to{
        color: green;

    }
}