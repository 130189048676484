#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 800px;
}

#arrowAnim {
  /* width: 50px; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 50px;
  height: 50px;
  border: 1.5vw solid;
  border-color: orange transparent transparent orange;
  transform: rotate(-225deg);
}


.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite; 
          animation: slide 4s linear infinite;
}

.delay1 {
  -webkit-animation-delay: 1s; 
    animation-delay: 1s;
}
.delay2 {
  -webkit-animation-delay: 2s; 
    animation-delay: 2s;
}
.delay3 {
  -webkit-animation-delay: 3s; 
    animation-delay: 2.2s;
}

@-webkit-keyframes slide {
    0% { opacity:0; transform: translateX(-15vw); }  
   20% { opacity:1; transform: translateX(-9vw); } 
   80% { opacity:1; transform: translateX(9vw); }  
  100% { opacity:0; transform: translateX(15vw); } 
}
@keyframes slide {
    0% { opacity:0; transform: translateX(-15vw); }  
   20% { opacity:1; transform: translateX(-9vw); } 
   80% { opacity:1; transform: translateX(9vw); }  
  100% { opacity:0; transform: translateX(15vw); } 
}